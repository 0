import { Observable } from 'rxjs';
import {
    AppsManagementResponse,
    CMSResponse,
    ICreateby,
} from '../../types/common.type';

export interface ISpecializes {
    id: string;
    published?: boolean;
    name?: string;
    created_by?: ICreateby;
    active?: boolean;
}

export abstract class SpecializesData {
    abstract GetSpecializes(): Observable<CMSResponse<ISpecializes[]>>;
}
